import axios from 'axios';

export default class LanguageListService {
  public async getLanguageList() {
    const languageListUrl = SERVER_API_URL + `content/languages.json?_=${I18N_HASH}`;
    const response = await axios.get<string[]>(languageListUrl);

    return response.data;
  }
}
