import axios, { AxiosResponse } from 'axios';

export class LocaleService {
  languages: AxiosResponse<any>;

  public async getLanguageDisplays(loc = window.location) {
    const port = loc.port ? ':' + loc.port : '';
    const nPoint = `//${loc.hostname}${port}/api/locales/display`;
    this.languages = await axios.get(nPoint);

    return await this.languages.data;
  }
}
