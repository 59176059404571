import Vue from 'vue';
import Component from 'vue-class-component';
import Ribbon from '@/core/ribbon/ribbon.vue';
import Footer from '@/core/footer/footer.vue';
import Navbar from '@/core/navbar/navbar.vue';

import '@/shared/config/dayjs';

@Component({
  components: {
    ribbon: Ribbon,
    navbar: Navbar,
    'footer-section': Footer,
  },
})
export default class App extends Vue {}
