import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here
const Imprint = () => import('@/pages/imprint-component.vue')

export default [
  {
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here}
    path: '/imprint',
    name: 'Imprint',
    component: Imprint,
  },
];
