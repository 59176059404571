var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("ribbon"),
      _vm._v(" "),
      _c("div", { attrs: { id: "app-header" } }, [_c("navbar")], 1),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c("div", { staticClass: "card jh-card" }, [_c("router-view")], 1),
          _vm._v(" "),
          _c("footer-section"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }