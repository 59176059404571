import axios, { AxiosResponse } from 'axios';

export default class ProfileManagementService {
  profileManagementUrl: AxiosResponse<any>;

  public async openProfileManagement(loc = window.location) {
    const port = loc.port ? ':' + loc.port : '';
    const nPoint = `//${loc.hostname}${port}/api/profile-management`;

    this.profileManagementUrl = await axios.get(nPoint);
    loc.href = this.profileManagementUrl.data.url;
  }
}
