import { Component, Inject, Vue } from 'vue-property-decorator';
import LoginService from '@/account/login.service';
import AccountService from '@/account/account.service';
import TranslationService from '@/locale/translation.service';
import EntitiesMenu from '@/entities/entities-menu.vue';
import ProfileManagementService from '@/profile-management/profile-management.service';
import LanguageListService from '@/core/util/language-list.service';
import { LocaleService } from '@/core/locale/locale.service';

@Component({
  components: {
    'entities-menu': EntitiesMenu,
  },
})
export default class JhiNavbar extends Vue {
  @Inject('loginService') private loginService: () => LoginService;
  @Inject('translationService') private translationService: () => TranslationService;
  @Inject('accountService') private accountService: () => AccountService;
  @Inject('profileManagementService') private profileManagementService: () => ProfileManagementService;
  @Inject('languageListService') private languageListService: () => LanguageListService;
  @Inject('localeService') private localeService: () => LocaleService;

  private currentLanguage = this.$store.getters.currentLanguage;
  private hasAnyAuthorityValues = {};
  public languages: string[] = [];
  public languageList: string[] = [];
  public gitCommitId = '';
  public version = '';

  async created() {
    this.languages = await this.languageListService().getLanguageList();
    this.languageList = await this.localeService().getLanguageDisplays();
    this.translationService().refreshTranslation(this.currentLanguage);
    if (VERSION) {
      this.version = VERSION.toLowerCase().startsWith('v') ? VERSION : `v${VERSION}`;
    }
    this.gitCommitId = GIT_COMMIT_ID;
  }

  public subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0; // current path starts with this path string
    });
  }

  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }

  public logout(): Promise<any> {
    return this.loginService()
      .logout()
      .then(response => {
        this.$store.commit('logout');
        window.location.href = response.data.logoutUrl;
        const next = response.data?.logoutUrl ?? '/';
        if (this.$route.path !== next) {
          return this.$router.push(next);
        }
      });
  }

  public openLogin(): void {
    this.loginService().login();
  }

  public get account(): any {
    return this.$store.getters.account ?? false;
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        this.hasAnyAuthorityValues[authorities] = value;
      });
    return this.hasAnyAuthorityValues[authorities] ?? false;
  }

  public get openAPIEnabled(): boolean {
    return this.$store.getters.activeProfiles.indexOf('api-docs') > -1;
  }

  public get inProduction(): boolean {
    return this.$store.getters.activeProfiles.indexOf('prod') > -1;
  }

  public async openProfileManagement() {
    try {
      await this.profileManagementService().openProfileManagement();
    } catch (error) {
      console.log(error.response);
    }
  }

  public findLanguageFromKey(lang: string) {
    const languageList = this.languageList;
    if (languageList?.[lang]) {
      return languageList[lang];
    }
    return lang;
  }
}
